<template>
  <tr>
    <td colspan="2" style="white-space: normal;max-width: 400px;">
      <div>
        <strong v-if="message.message_type_id === 5">{{$t('Advertisement')}}</strong>
        <strong v-if="message.message_type_id === 6">{{$t('Announcement')}}</strong>
        <div class="mt-2" v-if="showMessage"><span class="user-access-rights-description">{{ message.message }}</span></div>
      </div>
    </td>
    <td v-if="message.venue_ids.length">
      <div class="d-flex align-items-center venues">
        <b-badge
          pill
          class="mr-2 venue-badge d-flex justify-content-center align-items-center font-size-12">
          {{message.venue_ids.length}}
        </b-badge>
        <b-button @click="ShowAllVenues" class="user-access-rights-show-venues d-flex align-items-center">
          {{ $t('Show all venues') }}
          <img src="@/assets/images/popup-icon.svg" class="ml-1" alt="Popup icon">
        </b-button>
      </div>
    </td>
    <td>{{ DateFormat(message.created_at)  }}</td>
    <td>{{ message.count }}</td>
    <td class="d-flex align-items-center justify-content-start">
      <i @click="ShowMessages" :class=" showMessage ? 'bx-caret-up' : 'bx-caret-down'"
         class="bx ml-1 audio-commercials-dropdown"></i>
    </td>
  </tr>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      showMessage: false,
    }
  },
  props: ['message'],
  methods: {
    ShowMessages() {
      this.showMessage = !this.showMessage
    },
    ShowAllVenues(){
      this.$emit('ShowAllVenues', this.message.venue_ids);
    },
    DateFormat(timestamp){
      let d = new Date(timestamp * 1000);
      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      return `${day}.${month}.${year}`
    }
  },
})
</script>

<style scoped>
.table .custom-td{
  padding: 8px 12px;
}
</style>
