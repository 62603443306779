



































































import Vue, {PropType} from "vue";
import {VenueData} from "@/types";
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  name: "select-venues-modal-messages" as string,

  async mounted(): Promise<void> {
    await this.setVenuesSubscription();
    await this.setSubscriptionProducts();
  },

  props: {
    data: Array as PropType<Array<VenueData>>,
    showOnly: Boolean as PropType<Boolean>,
  },

  data() {
    return {
      checkAll: false,
      currentPage: 1,
      perPage: 32,
      selectedVenues: [] as Array<number>,
    }
  },

  computed: {
    ...mapGetters("venueSubscriptionModule", {
      venuesSubscription: "GET_VENUES_SUBSCRIPTION",
      subscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS"
    }),
    totalVenues(): number {
      return this.data.length;
    },
    venuesList(): Array<VenueData> {
      return this.data.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    premiumVenues(){
      let premiumVenues = [];
      this.data.map( x => {
          if(this.venueSubscription(x.id) !== 'Basic'){
           premiumVenues.push(x.id)
          }
        });
      return premiumVenues
    }
  },
  methods: {
    ...mapActions("venueSubscriptionModule", {
      setVenuesSubscription: "FETCH_VENUES_SUBSCRIPTION",
      setSubscriptionProducts: "FETCH_SUBSCRIPTION_PRODUCTS"
    }),
    selectAll(checked) {
      if (checked) {
        this.data.map( x => {
          if(this.venueSubscription(x.id) !== 'Basic'){
           this.selectedVenues.push(x.id)
            this.selectedVenues = [...new Set(this.selectedVenues)]
          }
        });
        this.checkAll = true;
      } else {
        this.selectedVenues = [];
        this.checkAll = false;
      }
    },
    venueSubscription(venueId: number) {
      let subscriptionPlan = this.venuesSubscription.find(s => s.venueId === venueId);
        if (!subscriptionPlan) return "";

        const product = this.subscriptionProducts.find(p => p.id === subscriptionPlan.stripeProductWithPriceId);

        if (product) return product.productName;
        return "";
      }
  },

  watch: {
    selectedVenues: {
      handler(value: Array<number>) {
        if([...new Set(value)].length < this.premiumVenues.length){
          this.checkAll = false;
        }else{
          this.checkAll = true;
        }
        this.$emit("setVenues", [...new Set(value)])
      }
    },

  }
})
