









































































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import NotificationMessage from '@/router/views/communications/messages-to-customers/NotificationMessage.vue';
import {mapActions, mapGetters} from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import DatePicker from "@/mixins/DatePicker";
import i18n from "@/i18n/i18n";

// Modals
import SelectVenuesModalMessages from '@/components/modals/SelectVenuesModalMessages.vue';
import MessagesToCustomersModal from '@/components/modals/MessagesToCustomersModal.vue';
import { VenueData, VenueSubscriptionData } from "@/types";
import Venue from "@/router/views/venues/Venue.vue";

export default Vue.extend({
  mixins: [DatePicker],
  name: "audio-commercials",
  components: {
    Layout,
    PageHeader,
    NotificationMessage,
    vSelect,
    "select-venues-modal-messages": SelectVenuesModalMessages,
    "messages-to-customers": MessagesToCustomersModal,

  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      title: 'Messages to customers',
      SelectedMessageType: '',
      message: '',
      modalMessage: '',
      startAt: '',
      endAt: '',
      venueModalOptions: {
        title: true,
        description: null,
        confirmBtn: true,
        tooltip: 'This venue has currently other active audio campaign. If you want to add this venue into the list, take it out first from the active audio campaign.'

      },
      selectedVenues: [] as Array<number>,
      messageType: '',
      charactersRemains: 0,
      maxCharacters: 250,
      allVenues:[],
      showOnly: false,
      uncheck: false,
      lang: DatePicker,
      i18: i18n
    };
  },

  async created(): Promise<void> {
    await this.checkLoggedUserRolePermission({ permissionId: 19 });
  },

  async mounted() {
    await this.setVenue({venue_id: localStorage.getItem("venue_id")});
    if (this.hasHistoryOfMessagesPermissions) {
      await this.setMessages({venue_owner_id: this.venue_owner_id});
    }
  },

  computed: {
    ...mapGetters("messagesToCustomersModule", {
      venue: "GET_SELECTED_VENUE",
      messages: "GET_MESSAGES",
      errorMessages: "GET_ERROR_MESSAGES",
    }),
    ...mapGetters("venueModule", {
      venues: "GET_VENUES"
    }),
    ...mapGetters("companyModule", {
      venue_owner_id: "GET_COMPANY_ID"
    }),
    ...mapGetters("userModule", {
      hasHistoryOfMessagesPermissions: "GET_HISTORY_OF_MESSAGES_PERMISSION"
    }),
    ...mapGetters("venueSubscriptionModule", {
      venueSubscriptionData: "GET_VENUES_SUBSCRIPTION"
    }),
    sendMessage() {
      if (this.messageType && this.selectedVenues.length && this.message !== '') {
        return true;
      }
      return false
    },
    disableFilter(): boolean {
      if (!this.startAt && !this.endAt) {
        this.setMessages({venue_owner_id: this.venue_owner_id});
        return true
      }
      return !this.startAt || !this.endAt;
    },
    totalMessages(): number {
      return this.messages.length;
    },
    messageList() {
      if (!this.messages.length) {
        return
      }
      return this.messages.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    filterDeactivatedVenues(){
      const deactivatedVenues: Array<number> = [] as Array<number>;

      if (!this.venueSubscriptionData.length) return;
      let nowTimestamp = Math.floor(Date.now() / 1000);
      this.venues.forEach((v: VenueData) => {
        const venueSubscription: VenueSubscriptionData = this.venueSubscriptionData.find(s => s.venueId === v.id);
        if(venueSubscription){
          if (Number(venueSubscription.subscriptionEndsAt) <  nowTimestamp || venueSubscription.subscriptionStatus == 'canceled') deactivatedVenues.push(v.id);
        }
      })
      return deactivatedVenues
    },
    nonExpiredVenues(){
      const availableVenues = []
      this.venues.forEach((v)=>{
        if(!this.filterDeactivatedVenues.includes(v.id)){
          availableVenues.push(v)
        }
      })
      return availableVenues
    }
  },

  methods: {
    ...mapActions("messagesToCustomersModule", {
      addMessageToCustomers: "ADD_MESSAGE_TO_CUSTOMERS",
      setVenue: "FETCH_VENUE",
      setMessages: "FETCH_MESSAGES",
    }),
    ...mapActions("userModule", {
      checkLoggedUserRolePermission: "CHECK_LOGGED_USER_ROLE_PERMISSION"
    }),
    async addMessage() {
      if (!this.sendMessage) {
        return
      }
      try {
        await this.addMessageToCustomers({
          data: {
            message: this.message,
            venue_owner_id: this.venue_owner_id,
            recipients_venue_ids: [...new Set(this.selectedVenues)]
          },
          messageType: this.messageType["value"]
        })
        this.modalMessage = this.messages
        this.$bvModal.show("messages-to-customers");
        this.message = '';
        this.messageType = '';
        this.charactersRemains = 0;
        this.maxCharacters = 250;
        this.uncheck = !this.uncheck;
      } catch (e) {
        if (this.errorMessages.error) {
          this.modalMessage = {error: this.$t('No recipients found for selected venues')};
          this.$bvModal.show("messages-to-customers");
        }
      }

    },
    setSelectedVenues(value: Array<number>): void {
      this.selectedVenues = value;
    },
    filterNotifications(){
      this.setMessages({
        venue_owner_id: this.venue_owner_id,
        startAt: this.startAt,
        endAt: this.endAt
      });
    },
    formatter(value) {
      this.charactersRemains = 0 + value.length
      return value.substring(0, 250)
    },
    ShowAllVenues(data){
      this.allVenues = this.nonExpiredVenues.filter(({id}) => data.includes(id));
      this.showOnly = false;
      this.$bvModal.show("select-venues-modal");
    },
    ChooseVenues(){
      this.allVenues = this.nonExpiredVenues;
      this.showOnly = true;
      this.$bvModal.show("select-venues-modal");
    }
  },
});
