















































import Vue from "vue";

export default Vue.extend({
  name: "messages-to-customers-modal" as string,
  props: ['message'],

})
